import { Paper, Tabs } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import styled from "styled-components";
const StyledTabs = styled(Tabs)({
    backgroundColor: blueGrey[700],
    marginLeft: '10px',
    marginRight: '10px',
});
export const StyledTab = {
    color: 'white !important',
};
export const StyledPaper = styled(Paper)({
    border: `1px solid ${blueGrey[900]}`,
    borderTopLeftRadius: '10px !important',
    borderTopRightRadius: '10px !important',
    backgroundColor: `${blueGrey[700]} !important`,
});
export default StyledTabs;
