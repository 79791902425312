import { blueGrey } from "@mui/material/colors";
import styled from "styled-components";
import { Button } from "@mui/material";
const StyledApp = styled.div({
    margin: "1rem",
    backgroundColor: blueGrey[800],
});
export const StyledButton = styled(Button)({
    marginTop: "0.5rem !important",
    marginBottom: "0.5rem !important",
});
export default StyledApp;
