var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import FileLoader from './file-loader/FileLoader';
import SboxEditModal from './SboxEditModal';
import { Box, Grid, LinearProgress, List, ListItem, ListItemText, Typography } from '@mui/material';
import TabHeader from './tabs/TabHeader';
import StyledApp, { StyledButton } from './App.styles';
import { lightBlue } from '@mui/material/colors';
import RadarChart from '../utils/RadarChart';
import { getSBoxById, initDatabase } from '../services/sboxService';
const App = (props) => {
    const mappingFileProperty = [
        { fileName: 'nonlinearitySBox.php', propertyName: 'non-linearity' },
        { fileName: 'confusionCoefficientSBox.php', propertyName: 'CCV' },
        { fileName: 'transparencyOrderSBox.php', propertyName: 'MTO' },
        { fileName: 'rtransparencyOrderSBox.php', propertyName: 'RTO' },
        { fileName: 'differentialUniformity.php', propertyName: 'DU' },
        { fileName: 'wcfSBox.php', propertyName: 'WCF' }
    ];
    const [appState, setAppState] = useState({
        r: 16,
        n: 8,
        m: 8,
        sBox: [],
        classicalSBoxes: [],
        progressBarPercent: 0,
    });
    const [results, setResults] = useState([]);
    const setSBox = function (sBox) {
        setAppState(Object.assign(Object.assign({}, appState), { sBox: sBox.map(v => parseInt(v)) }));
    };
    const setClassicalSBox = function (el) {
        setAppState(Object.assign(Object.assign({}, appState), { n: Number(el.n), m: Number(el.m), progressBarPercent: 0 }));
        setSBox(el.outputs.split(' '));
    };
    const loadClassicalSBoxes = function () {
        fetch(`${location.origin}/classicalSBoxes.php`).then(response => response.json()).then(data => {
            setAppState(Object.assign(Object.assign({}, appState), { classicalSBoxes: data }));
        });
    };
    const evaluation = function () {
        return __awaiter(this, void 0, void 0, function* () {
            setResults([{ description: "> The evaluation process has been started." }]);
            if (appState.sBox.length != (1 << appState.n)) {
                setResults([{ description: "> There are to much inputs for the function." }]);
                return;
            }
            let obj = { n: appState.n, m: appState.m, sBox: appState.sBox };
            let e_results = [];
            const balancedResponse = yield fetch(`properties/balanced`, {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(obj),
                headers: { 'Content-Type': 'application/application/json' }
            });
            const balancedData = yield balancedResponse.json();
            e_results.push({ description: `> The S-box is ${balancedData.value ? "" : "not"} balanced.` });
            let mapping = ['nl', 'wcf', 'du', 'ccv', 'mto', 'rto'];
            for (let index = 0; index < mapping.length; index++) {
                let property = mapping[index];
                const response = yield fetch(`properties/${property}`, {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify(obj),
                    headers: { 'Content-Type': 'application/application/json' }
                });
                const data = yield response.json();
                e_results.push({
                    description: `> The S-box's ${property.toUpperCase()} is ${data.value}.`, propertyResult: {
                        property: property.toUpperCase(),
                        value: data.value
                    }
                });
            }
            setResults(e_results);
        });
    };
    const generateRandomSBox = function () {
        return __awaiter(this, void 0, void 0, function* () {
            setAppState(Object.assign(Object.assign({}, appState), { sBox: [], progressBarPercent: 0 }));
            setResults([]);
            if (appState.n == appState.m && appState.n == 8) {
                const response = yield fetch(`generation/random`);
                let data = yield response.json();
                setAppState(Object.assign(Object.assign({}, appState), { sBox: data }));
            }
            else
                alert("The SBox size must be 8x8.");
        });
    };
    const generateInversionMapSBox = function () {
        return __awaiter(this, void 0, void 0, function* () {
            setAppState(Object.assign(Object.assign({}, appState), { sBox: [], progressBarPercent: 0 }));
            setResults([]);
            if (appState.n == appState.m && appState.n == 8) {
                const response = yield fetch(`generation/inversionmap`);
                let data = yield response.json();
                setAppState(Object.assign(Object.assign({}, appState), { sBox: data }));
            }
            else
                alert("The SBox size must be 8x8.");
        });
    };
    const generateMordellCurveSBox = function () {
        return __awaiter(this, void 0, void 0, function* () {
            setAppState(Object.assign(Object.assign({}, appState), { sBox: [], progressBarPercent: 0 }));
            setResults([]);
            if (appState.n == appState.m && appState.n == 8) {
                const response = yield fetch(`generation/mordellcurve`);
                let data = yield response.json();
                setAppState(Object.assign(Object.assign({}, appState), { sBox: data }));
            }
            else
                alert("The SBox size must be 8x8.");
        });
    };
    const generateSBoxWithHighNL = function (nlGoal) {
        return __awaiter(this, void 0, void 0, function* () {
            setAppState(Object.assign(Object.assign({}, appState), { sBox: [], n: 8, m: 8, progressBarPercent: 0 }));
            setResults([]);
            const response = yield fetch(`generation/random`);
            let data = yield response.json();
            const response1 = yield fetch(`properties/nl`, {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({ n: appState.n, m: appState.m, sBox: data }),
                headers: { 'Content-Type': 'application/application/json' }
            });
            const response1result = yield response1.json();
            let dataNL = response1result.value;
            const response2 = yield fetch(`properties/wcf`, {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({ n: appState.n, m: appState.m, sBox: data }),
                headers: { 'Content-Type': 'application/application/json' }
            });
            const response2result = yield response2.json();
            let dataWCF = response2result.value;
            let a = 0;
            let b = 1;
            const n_shift = 1 << appState.n;
            while (true) {
                let ndata = [...data];
                let temp = ndata[a];
                ndata[a] = ndata[b];
                ndata[b] = temp;
                const response3 = yield fetch(`properties/nl`, {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify({ n: appState.n, m: appState.m, sBox: ndata }),
                    headers: { 'Content-Type': 'application/application/json' }
                });
                const response3result = yield response3.json();
                const ndataNL = response3result.value;
                const response4 = yield fetch(`properties/wcf`, {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify({ n: appState.n, m: appState.m, sBox: ndata }),
                    headers: { 'Content-Type': 'application/application/json' }
                });
                const response4result = yield response4.json();
                const ndataWCF = response4result.value;
                if (ndataNL > dataNL || (ndataNL == dataNL && ndataWCF < dataWCF)) {
                    data = [...ndata];
                    dataNL = ndataNL;
                    dataWCF = ndataWCF;
                    let rest = dataNL - 94;
                    let percent = rest < 0 ? 0 : (rest * 100) / (nlGoal - 94);
                    setAppState(Object.assign(Object.assign({}, appState), { sBox: [], progressBarPercent: percent }));
                    if (ndataNL === nlGoal) {
                        setAppState(Object.assign(Object.assign({}, appState), { sBox: data, progressBarPercent: 0 }));
                        break;
                    }
                }
                b++;
                if (b == n_shift) {
                    a++;
                    b = a + 1;
                    if (a == n_shift - 1) {
                        a = 0;
                        b = 1;
                    }
                }
            }
        });
    };
    const handleSBoxValueChange = function (event) {
        setAppState(Object.assign(Object.assign({}, appState), { sBox: event.target.value }));
    };
    const getFileString = function (val) {
        setAppState(Object.assign(Object.assign({}, appState), { sBox: [], progressBarPercent: 0 }));
        setResults([]);
        let base = 10;
        let tempSBox;
        if (/.*[A-Z].*/.test(val) || /.*[a-z].*/.test(val)) {
            base = 16;
        }
        tempSBox = val.replace(/\r?\n/, ' ').trim().split(/\s+/).map(x => parseInt(x, base));
        if (tempSBox.length != (1 << appState.n)) {
            alert(`The loaded file are not correct in size of inputs, in correspondence with N: ${appState.n} bits.`);
            return;
        }
        if (tempSBox.some(x => x < 0 || x >= (1 << appState.m))) {
            alert(`The loaded file contains an incorrect output, in correspondence with M: ${appState.m} bits.`);
            return;
        }
        setAppState(Object.assign(Object.assign({}, appState), { sBox: tempSBox }));
    };
    const getNValue = function (val) {
        setAppState(Object.assign(Object.assign({}, appState), { n: val }));
    };
    const getMValue = function (val) {
        setAppState(Object.assign(Object.assign({}, appState), { m: val }));
    };
    const [open, setOpen] = React.useState(false);
    return (React.createElement(StyledApp, null,
        React.createElement(TabHeader, null),
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 4 },
                        React.createElement(StyledButton, { variant: "contained", onClick: generateRandomSBox, fullWidth: true }, "Random"),
                        React.createElement(StyledButton, { variant: "contained", onClick: generateInversionMapSBox, fullWidth: true }, "Inversion Map"),
                        React.createElement(StyledButton, { variant: "contained", onClick: generateMordellCurveSBox, fullWidth: true }, "Mordell Curve"),
                        React.createElement(StyledButton, { variant: "contained", onClick: () => generateSBoxWithHighNL(100), fullWidth: true }, "Local search (NL: 100)"),
                        React.createElement(StyledButton, { variant: "contained", onClick: () => generateSBoxWithHighNL(102), fullWidth: true }, "Local search (NL: 102)"),
                        React.createElement(StyledButton, { variant: "contained", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                yield initDatabase();
                                const sBox = yield getSBoxById('aes');
                                setAppState((prevState) => (Object.assign(Object.assign({}, prevState), { sBox, progressBarPercent: 0 })));
                            }), fullWidth: true }, "Load AES-SBox")),
                    React.createElement(Grid, { item: true, xs: 8 },
                        React.createElement(FileLoader, { setFileString: getFileString, setNValue: getNValue, setMValue: getMValue, n: appState.n, m: appState.m }),
                        React.createElement("div", { className: "col-md-8" },
                            React.createElement("div", { className: "header" },
                                " ",
                                React.createElement(Typography, { variant: "body1" },
                                    "  S-box (vectorial boolean function) ",
                                    appState.n,
                                    "x",
                                    appState.m,
                                    " "),
                                "  ",
                                React.createElement(SboxEditModal, { sBox: appState.sBox, setSBox: setSBox }),
                                " "),
                            React.createElement("div", { className: "bg-light text-dark" }, appState.sBox.length > 0 &&
                                React.createElement("div", null, Array.from({ length: 16 }, (_, i) => (React.createElement("div", { key: i, style: { display: 'flex' } }, appState.sBox.slice(i * 16, (i + 1) * 16).map((value, j) => (React.createElement("div", { key: j, style: { width: '40px', textAlign: 'center', border: '1px solid black', backgroundColor: `${lightBlue[300]}`, fontSize: '15px', color: 'black' } }, value)))))))),
                            React.createElement(LinearProgress, { variant: "determinate", value: appState.progressBarPercent }))))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(StyledButton, { variant: "contained", onClick: evaluation }, " Evaluate  "),
                        React.createElement("div", { className: "col-md-4" },
                            React.createElement(Typography, { variant: "body1" }, "Output"),
                            results.length > 0 &&
                                React.createElement(List, { sx: { backgroundColor: 'black' } }, results.map((result, index) => React.createElement(ListItem, { key: index, disablePadding: true },
                                    React.createElement(ListItemText, { primary: result.description, sx: { backgroundColor: 'black', color: 'white' } })))))),
                    React.createElement(Grid, { item: true, xs: 6, sx: { marginTop: '10px' } },
                        React.createElement(RadarChart, { label: 'DPA related properties', data: results.filter(log => log.propertyResult).map(log => log.propertyResult), max: appState.n }))))),
        React.createElement(Box, { sx: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '80vh', // Adjust height as needed
            } },
            React.createElement("img", { src: "/static/img/ceibacofull.svg", width: "64", height: "64", alt: "logo" }),
            React.createElement(Typography, { variant: "caption", align: "center" },
                "beta-2 version. All rights reserved ",
                React.createElement("i", { className: "fa fa-copyright", "aria-hidden": "true" }),
                " ",
                new Date().getFullYear(),
                "."))));
};
export default App;
