const DB_NAME = 'CeibacoDB';
const DB_VERSION = 1;
const STORE_NAME = 'SBoxes';
function openDatabase() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, DB_VERSION);
        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME, { keyPath: 'id' });
            }
        };
        request.onsuccess = (event) => {
            resolve(event.target.result);
        };
        request.onerror = (event) => {
            reject(event.target.error);
        };
    });
}
function saveSBox(guid, sBox) {
    return openDatabase().then((db) => {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(STORE_NAME, 'readwrite');
            const store = transaction.objectStore(STORE_NAME);
            const request = store.put({ id: guid, sBox });
            request.onsuccess = () => {
                resolve();
            };
            request.onerror = (event) => {
                reject(event.target.error);
            };
        });
    });
}
function getSBox(id) {
    return openDatabase().then((db) => {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(STORE_NAME, 'readonly');
            const store = transaction.objectStore(STORE_NAME);
            const request = store.get(id);
            request.onsuccess = (event) => {
                const result = event.target.result;
                if (result) {
                    resolve(result.sBox);
                }
                else {
                    resolve([]);
                }
            };
            request.onerror = (event) => {
                reject(event.target.error);
            };
        });
    });
}
function getAllSBoxes() {
    return openDatabase().then((db) => {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(STORE_NAME, 'readonly');
            const store = transaction.objectStore(STORE_NAME);
            const request = store.getAll();
            request.onsuccess = (event) => {
                const result = event.target.result;
                resolve(result.map((item) => item.sBox));
            };
            request.onerror = (event) => {
                reject(event.target.error);
            };
        });
    });
}
export { saveSBox, getSBox, getAllSBoxes };
