import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { Card, CardContent, Typography } from '@mui/material';
import { PropertyName } from '../domain/PropertyResult';
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);
const RadarChart = ({ label, max, data }) => {
    const radarProperties = [PropertyName.CCV, PropertyName.MTO, PropertyName.RTO];
    const chartData = {
        labels: radarProperties,
        datasets: [
            {
                label,
                data: data.filter((d) => radarProperties.includes(d.property)).map((d) => d.value),
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
        ],
    };
    const options = {
        scales: {
            r: {
                angleLines: {
                    display: true,
                },
                suggestedMin: 0,
                suggestedMax: max !== null && max !== void 0 ? max : 100,
            },
        },
    };
    return (React.createElement(Card, null,
        React.createElement(CardContent, null,
            React.createElement(Typography, { variant: "h5", component: "div" }, "Radar Chart"),
            React.createElement(Radar, { data: chartData, options: options }))));
};
export default RadarChart;
