import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import { blueGrey } from '@mui/material/colors';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
// Add global CSS to remove margin from body
const style = document.createElement('style');
style.innerHTML = `
  body {
    margin: 0;
    background-color: ${blueGrey[800]};
  }
`;
document.head.appendChild(style);
root.render(React.createElement(StrictMode, null,
    React.createElement(App, { tab: "home" })));
