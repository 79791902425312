import React from 'react';
import './css/FileLoader.css';
const FileLoader = (props) => {
    let fileReader;
    const handleFileRead = (e) => {
        const content = fileReader.result;
        props.setFileString(content);
    };
    const handleFileChosen = (file) => {
        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsText(file);
    };
    return (React.createElement("div", { id: "fileloader", className: "d-flex d-row align-items-center" },
        React.createElement("label", null, "N:"),
        React.createElement("input", { type: "number", step: "1", min: "1", max: "16", value: props.n, onChange: e => props.setNValue(parseInt(e.target.value)) }),
        React.createElement("label", null, "M:"),
        React.createElement("input", { type: "number", step: "1", min: "1", max: "16", value: props.m, onChange: e => props.setMValue(parseInt(e.target.value)) }),
        React.createElement("label", null, "File:"),
        React.createElement("input", { type: "file", accept: ".txt", onChange: e => handleFileChosen(e.target.files[0]) })));
};
export default FileLoader;
